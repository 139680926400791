export type PostOnboardingAction = "join_tribe" | "get_ticket" | "view_event";

interface StoredOnboardingData {
  action: PostOnboardingAction | null;
  eventId: string | null;
  ticketUrl: string | null;
}

const POST_ONBOARDING_KEY = "tribein_post_onboarding";

export function updatePostOnboardingData(
  update: Partial<StoredOnboardingData>
) {
  const current = getPostOnboardingAction() || {
    action: null,
    eventId: null,
    ticketUrl: null,
  };
  localStorage.setItem(
    POST_ONBOARDING_KEY,
    JSON.stringify({ ...current, ...update })
  );
}

export function getPostOnboardingAction(): StoredOnboardingData | null {
  try {
    const data = localStorage.getItem(POST_ONBOARDING_KEY);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    return null;
  }
}

export function clearPostOnboardingAction() {
  localStorage.removeItem(POST_ONBOARDING_KEY);
}
