import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    preferences: null,
    otpCredToResend: {
      phone_number: "",
      country_code: "",
    },
    isUserLogedIn: false,
    userLocation: null,
    joiningDetails: {},
  },
  reducers: {
    logout: (state, action) => {
      localStorage.clear();
      state.user = null
      state.preferences = null
      state.isUserLogedIn = false;
    },
    setUserLogin: (state, action) => {
      state.isUserLogedIn = true;
    },
    setOtpCredToResend: (state, action) => {
      state.otpCredToResend = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setPreferences: (state, action) => {
      state.preferences = action.payload;
    },
    setUserLocation: (state, action) => {
      state.userLocation = action.payload;
    },
    setJoiningDetails: (state, action) => {
      state.joiningDetails = action.payload;
    },
  },
});

export const {
  setOtpCredToResend,
  setUserLogin,
  logout,
  setUser,
  setUserLocation,
  setPreferences,
  setJoiningDetails,
} = userSlice.actions;

export default userSlice.reducer;
