import { z } from "zod";
import UpdateProfileWrapper from "../modals/updateProfile/UpdateProfileWrapper";
import { UserProfile } from "../types/user.types";
import { useDispatch } from "react-redux";
import { openUpdateProfile } from "../redux/Reducers/modalReducer";
import { useEffect } from "react";

interface Props {
  user: UserProfile;
}

const validUserSchema = z.object({
  gender: z.string(),
  dob: z.string(),
  email: z.string().email(),
  first_name: z.string(),
  last_name: z.string(),
  phone_number: z.object({}),
  address: z.object({
    name: z.string(),
    loc: z.object({
      coordinates: z.array(z.number()),
    }),
  }),
});

function EnsureAccountVerification(props: Props) {
  const { user } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      user &&
      (!validUserSchema.safeParse(user).success || !user.is_profile_completed)
    ) {
      dispatch(openUpdateProfile({}));
    }
  }, [user, dispatch]);

  if (
    !user ||
    !validUserSchema.safeParse(user).success ||
    !user.is_profile_completed
  ) {
    return <UpdateProfileWrapper user={user} />;
  }

  return null;
}

export default EnsureAccountVerification;
