import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
  closeOtpModal,
  openProfileDetails,
} from "../../redux/Reducers/modalReducer";
import { addUserDetails, sendOtp, verifyOtp } from "../../api/api";
import { toast } from "react-toastify";
import { setUser, setOtpCredToResend } from "../../redux/Reducers/userReducer";
import { setUserLogin } from "../../redux/Reducers/userReducer";
import Backicon from "../../assets/img/Backicon.svg";
import { getPostOnboardingAction } from "../../utils/postOnboardingAction";

const OtpVerify = (props) => {
  let { otpCredToResend } = useSelector((state) => state?.user);
  const { data } = useSelector((state) => state.model);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otpError, setOtpError] = useState("");
  const postOnboardingAction = getPostOnboardingAction();

  useEffect(() => {
    if (data?.otp) {
      setOtp(data?.otp);
    }
  }, [data]);

  const handleResendOtp = async () => {
    let dataToSend;

    if (data?.user_id) {
      dataToSend = {
        device_type: "phone",
        user_id: data?.user_id,
        phone_number: otpCredToResend.phone_number,
        country_code: otpCredToResend.country_code,
      };
    } else {
      dataToSend = {
        device_type: "phone",
        phone_number: otpCredToResend.phone_number,
        country_code: otpCredToResend.country_code,
      };
    }

    const response = await sendOtp(dataToSend);
    dispatch(
      setOtpCredToResend({
        phone_number: otpCredToResend.phone_number,
        country_code: otpCredToResend.country_code,
      })
    );
    if (response?.statusCode === 200) {
      localStorage.setItem("token", response.data.token);
      toast.success(response.message);
    } else {
      toast.error(response.error);
    }
  };
  const back = () => {
    dispatch(closeOtpModal());
  };
  const handleSubmit = async () => {
    try {
      if (!otp) {
        setOtpError("OTP is required");
        return;
      }
      setIsLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        otp: otp,
      };
      const response = await verifyOtp(data);
      if (response.statusCode === 200) {
        localStorage.setItem("token", response.data.token);
        await addUserDetails({
          is_phone_number_verified: true,
        });
        dispatch(setUserLogin(true));
        dispatch(setUser(response?.data?.user));

        if (response.data.user.is_profile_completed === true) {
          toast.success(response.message);
          dispatch(setUser(response.data.user));
          dispatch(closeOtpModal());
          dispatch(setUserLogin());
          if (postOnboardingAction && postOnboardingAction.action) {
            const redirectUrl = `/event/${
              postOnboardingAction.eventId
            }?from=auth&action=${postOnboardingAction.action.toLowerCase()}`;
            navigate(redirectUrl);
          } else {
            navigate("/");
          }
        } else {
          toast.success(response.message);
          dispatch(closeOtpModal());
          dispatch(openProfileDetails());
        }
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="sign_in_modal signin_inner_modal otp_inner_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2
              onClick={back}
              className="back_arrow_signin"
              style={{ justifyContent: "left" }}
            >
              <div className="back_profile_icon">
                <img src={Backicon} alt="logo-icon" className="Backicon" />
              </div>
              {/* Sign in */}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Enter Verification Code</h4>
          {/* <p>
            (+{otpCredToResend.country_code}) {otpCredToResend.phone_number}{" "}
            <Link onClick={handleResendOtp}>Resend</Link>
          </p> */}
          <div className="otp_code">
            <label
              htmlFor="otp-input"
              className="text-white"
              style={{ marginBottom: "1rem" }}
            >
              Enter the 6-digit code sent to your phone.
            </label>
            <br />
            <br />
            <OtpInput
              id="otp-input"
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span> </span>}
              inputStyle={{}}
              inputType="tel"
              renderInput={(props) => (
                <input {...props} className="input-container-otp-modal" />
              )}
            />
          </div>
          {otpError && otp.length < 6 && (
            <small className="text-danger">{otpError}</small>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleSubmit}
            className={otp.length === 6 ? "is_btn_active" : ""}
          >
            Verfiy Me
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OtpVerify;
