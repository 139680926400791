import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Image as ImageIcon, X, Loader2, AlertCircle } from "lucide-react";
import { toast } from "react-toastify";
import { uploadFile } from "../../../../api/api";

const ChatImageUploader = () => {
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleUpload = async (file) => {
    try {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", file);

      const imageUrl = await uploadFile(formData);

      if (imageUrl) {
        toast.success("Image uploaded successfully");
      }
    } catch (error) {
      toast.error("Failed to upload image. Please try again.");
      console.error("Image upload error:", error);
      setPreview(null);
    } finally {
      setUploading(false);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (rejectedFiles.length > 0) {
      const error = rejectedFiles[0].errors[0];
      if (error.code === "file-too-large") {
        toast.error("Image must be smaller than 5MB");
      } else if (error.code === "file-invalid-type") {
        toast.error(
          "Please select a valid image file (JPEG, PNG, GIF, or BMP)"
        );
      }
      return;
    }

    const file = acceptedFiles[0];
    if (!file) return;

    // Create preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);

    await handleUpload(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
      "image/bmp": [],
    },
    maxSize: 5 * 1024 * 1024, // 5MB
    multiple: false,
  });

  const removeImage = (e) => {
    e.stopPropagation();
    setPreview(null);
  };

  return (
    <div {...getRootProps()} className="relative inline-block">
      <input {...getInputProps()} />

      <button
        className={`relative group flex  items-center justify-center w-10 h-10 rounded-full 
                   transition-all duration-200 bg-primary-900 disabled:opacity-50
                   ${
                     isDragActive
                       ? "bg-blue-50 ring-2 ring-blue-500"
                       : " text-primary-50"
                   }`}
        disabled={uploading}
        title="Upload image"
      >
        {uploading ? (
          <Loader2 className="h-5 w-5 text-blue-500 animate-spin" />
        ) : preview ? (
          <div className="relative">
            <div className="w-6 h-6 rounded-md overflow-hidden">
              <img
                src={preview}
                alt="Preview"
                className="w-full h-full object-cover"
              />
            </div>
            <button
              onClick={removeImage}
              className="absolute -top-1 -right-1 p-0.5 rounded-full bg-gray-900 
                       text-white hover:bg-gray-700 transition-colors"
            >
              <X className="h-3 w-3" />
            </button>
          </div>
        ) : (
          <ImageIcon
            className={`h-5 w-5 transition-colors ${
              isDragActive
                ? "text-blue-500"
                : "text-gray-500 group-hover:text-gray-600"
            }`}
          />
        )}
      </button>

      {/* Drag overlay */}
      {isDragActive && (
        <div className="fixed inset-0 bg-blue-500 bg-opacity-10 z-50 pointer-events-none" />
      )}

      {/* Tooltip */}
      <div
        className={`absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 
                    text-xs bg-gray-900 rounded text-white
                    transition-opacity duration-200 pointer-events-none whitespace-nowrap
                    ${
                      isDragActive
                        ? "opacity-0"
                        : "opacity-0 group-hover:opacity-100"
                    }`}
      >
        {isDragActive ? "Drop image here" : "Upload image"}
      </div>
    </div>
  );
};

export default ChatImageUploader;
