import { z } from "zod";

const SenderSchema = z.object({
  _id: z.string(),
  profile_pic: z
    .string()
    .nullable()
    .optional()
    .refine(
      (val) => {
        if (!val) return true; // Allow null/undefined
        try {
          new URL(val);
          return true;
        } catch {
          return false;
        }
      },
      { message: "Invalid URL format" }
    ),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  first_name: z.string(),
});

export type SenderSchema = z.infer<typeof SenderSchema>;

const TribeEventLocationSchema = z.object({
  type: z.literal("Point"),
  coordinates: z.array(z.number()).length(2),
});

const TribeSchema = z.object({
  _id: z.string(),
  name: z.string(),
  event_id: z.string(),
  event_name: z.string(),
  event_location: TribeEventLocationSchema.nullable(),
  event_start_date: z.string().datetime(),
  event_end_date: z.string().datetime(),
  tribe_logo: z.string().url(),
  is_deleted: z.boolean(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  __v: z.number(),
  totalJoinees: z.number().optional(),
});

export type MessageTribe = z.infer<typeof TribeSchema>;

const MessageSchema = z.object({
  _id: z.string(),
  sender_id: z.string(),
  tribe_id: z.string(),
  message: z.string(),
  read_by: z.array(z.string()),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  sender: SenderSchema,
  tribe: TribeSchema,
  type: z.string(),
});

// Main response schema
const MessageResponseSchema = z.object({
  docs: z.array(MessageSchema).min(0),
  totalDocs: z.number(),
  hasMore: z.boolean(),
  currentPage: z.number(),
  totalPages: z.number(),
  limit: z.number(),
});

export type MessageSchema = z.infer<typeof MessageSchema>;
export type MessageResponse = z.infer<typeof MessageResponseSchema>;

export default MessageResponseSchema;
