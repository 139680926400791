import { User } from "@/Component/shared/VerifyUserInfo";
import { forwardRef, useEffect, useCallback, useRef, useState } from "react";
import { MessageSchema, MessageTribe } from "../../hooks/messages.schema";
import ChatMessage from "./ChatMessage";
import { NewMessageIndicator } from "./NewMessageIndicator";

interface Props {
  chatMessages: {
    docs: MessageSchema[];
  }[];
  user: User;
  onScrollTop: () => void;
  numberOfPages: number;
  selectedTribe: MessageTribe;
}

const DynamicChatContainer = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { chatMessages, user, onScrollTop, selectedTribe } = props;
  const messages = chatMessages.flatMap((page: any) => page.docs);
  const isFirstRender = useRef(true);
  const [showNewMessageIndicator, setShowNewMessageIndicator] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<string | null>(null);

  const scrollToBottom = useCallback(() => {
    if (typeof ref !== "function" && ref?.current) {
      const container = ref.current.parentElement;
      if (container) {
        requestAnimationFrame(() => {
          container.scrollTop = container.scrollHeight;
          setShowNewMessageIndicator(false);
        });
      }
    }
  }, [ref]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollTop === 0) {
      onScrollTop();
    }

    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
    const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;

    if (isNearBottom) {
      setShowNewMessageIndicator(false);
    }
  };

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (!lastMessage) return;

    // Only show indicator if:
    // 1. Message is from another user
    // 2. We're not at the bottom
    // 3. It's not the first render
    // 4. It's a different message than last time
    if (
      lastMessage.sender._id !== user._id &&
      containerRef.current &&
      !isFirstRender.current &&
      lastMessage._id !== lastMessageRef.current
    ) {
      const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;

      if (!isNearBottom) {
        setShowNewMessageIndicator(true);
      }
    }

    if (isFirstRender.current || lastMessage._id.startsWith("temp-")) {
      scrollToBottom();
      isFirstRender.current = false;
    }

    lastMessageRef.current = lastMessage._id;
  }, [messages, scrollToBottom, user._id]);

  return (
    <div className="relative h-full">
      <div
        ref={containerRef}
        data-message-container="true"
        className="h-full overflow-y-auto flex-grow pt-16 md:pt-0 pb-0 sm:pb-20"
        onScroll={handleScroll}
      >
        {!messages.length && selectedTribe.totalJoinees === 1 && (
          <div className="flex flex-col items-center justify-center h-full p-8 text-center pt-[20vh]">
            <div className="max-w-md space-y-4">
              <div className="text-xl font-semibold text-primary-200">
                Welcome to your Tribe for {selectedTribe.event_name}!
              </div>
              <p className="text-primary-400 text-sm leading-relaxed">
                You're the first one here. Don't worry—others will join soon.
                Feel free to set the vibe and start the conversation while we
                bring more people in.
              </p>
            </div>
          </div>
        )}{" "}
        {!messages.length &&
          selectedTribe.totalJoinees &&
          selectedTribe.totalJoinees > 1 && (
            <div className="flex flex-col items-center justify-center h-full p-8 text-center pt-[20vh]">
              <div className="max-w-md space-y-4">
                <p className="text-primary-400 text-sm leading-relaxed italic">
                  Break the ice! Say hi to your Tribe and start the
                  conversation.
                </p>
              </div>
            </div>
          )}
        {/* Message list */}
        {messages.map((doc) => {
          const isCurrentUser = doc.sender._id === user?._id;
          return (
            <ChatMessage
              key={doc._id}
              isCurrentUser={isCurrentUser}
              message={doc}
            />
          );
        })}
        <div ref={ref}></div>
        {/* Messages end div for scrolling */}
      </div>
      <NewMessageIndicator
        visible={showNewMessageIndicator}
        onClick={() => {
          scrollToBottom();
          setShowNewMessageIndicator(false);
        }}
      />
    </div>
  );
});

// Add display name for better debugging
DynamicChatContainer.displayName = "DynamicChatContainer";

export default DynamicChatContainer;
