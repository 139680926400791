import React, { useState } from "react";
import { Button } from "../../Component/shared/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Succes from "../../assets/img/Succes.svg";
import { closeFeedbackModal } from "../../redux/Reducers/modalReducer";
import { useDispatch } from "react-redux";

const Yourfeedback = (props) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        {...props}
        className="sign_in_modal categories_modal fillter_categories_modal request_successfully_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0 flex items-center justify-center">
          <div className="reportsuccessfully_cntnt">
            <div className="reportsuccessfully_icon flex justify-center">
              <img src={Succes} alt="Succes" />
            </div>
            <div className="space-y-8 pb-4">
              <h3 className="font-bold text-2xl md:text-4xl md:leading-[52.5px]">
                Your feedback has been received!
              </h3>
              <p className="font-normal">
                Thank you for taking the time to share your thoughts.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="completeyour_profile_btn profile_photo_btn m-0">
            <Button
              weight={"bold"}
              size="xl"
              onClick={() => dispatch(closeFeedbackModal())}
            >
              Done
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Yourfeedback;
