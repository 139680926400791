import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import LoadingSpinner from "../../../../Component/shared/LoadingSpinner";
import { useSocket } from "../../../../context/SocketContext";
import { MessageTribe } from "../../hooks/messages.schema";
import { useInfiniteMessages } from "../../hooks/useMessages";
import { useSendMessage } from "../../hooks/useSendMessage";
import ChatHeader from "./ChatHeader";
import ChatMessageList from "./ChatMessageList";
import GifSelector from "./GifSelector";
import ChatImageUploader from "./UploadPhoto";
import { openExitTribeConfirmationModal } from "../../../../redux/Reducers/modalReducer";
import { CHAT_MESSAGE_CLASS } from "../../../../helpers/constants";
import mixpanel from "mixpanel-browser";
import { useQueryClient, InfiniteData } from "@tanstack/react-query";
import { MessageResponse } from "../../hooks/messages.schema";

interface Props {
  selectedTribeId: string;
  selectedTribe: MessageTribe;
  onBackClick: () => void;
  onToggleMembers: () => void;
  showMembers: boolean;
}

function MessageArea(props: Props) {
  const {
    selectedTribeId,
    selectedTribe,
    onBackClick,
    onToggleMembers,
    showMembers,
  } = props;
  const [message, setMessage] = useState("");
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const socket = useSocket();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const { user } = useSelector((state: any) => state?.user);
  const queryClient = useQueryClient();

  const { data, status, fetchNextPage } = useInfiniteMessages(
    selectedTribe._id
  );
  const previousTribeId = useRef(selectedTribe._id);

  const { sendMessage: sendMessageMutation } = useSendMessage({
    sender_id: user?._id,
    tribe_id: selectedTribeId,
    tribeName: "",
    user: user,
    tribe: selectedTribe,
  });

  useEffect(() => {
    if (!socket) return;
    socket.emit("join chat");

    return () => {
      socket.off("socketOff");
    };
  }, [socket]);

  useEffect(() => {
    if (!socket || !user?._id || !selectedTribeId) return;

    // Join the specific chat room
    socket.emit("join chat", selectedTribeId);

    // Message handler for this specific chat
    const handleMessage = (newMessage: any) => {
      // Only process messages for current tribe
      if (newMessage.tribe_id !== selectedTribeId) return;

      queryClient.setQueryData<InfiniteData<MessageResponse>>(
        ["messages", selectedTribeId],
        (old: any) => {
          if (!old) return old;

          return {
            ...old,
            pages: [
              {
                ...old.pages[0],
                docs: [...old.pages[0].docs, newMessage],
              },
              ...old.pages.slice(1),
            ],
          };
        }
      );
    };

    // Add listener for this chat
    socket.on("receive message", handleMessage);

    return () => {
      // Leave the chat room
      socket.emit("leave chat", selectedTribeId);
      // Remove listener
      socket.off("receive message", handleMessage);
    };
  }, [socket, user?._id, selectedTribeId, queryClient]);

  const sendMessage = async () => {
    if (!message) return;
    mixpanel.track("Message sent", {
      "Event Name": selectedTribe.event_name,
      "Event ID": selectedTribe._id,
    });
    sendMessageMutation({ newMessage: message });
    setMessage("");
  };

  function handleScrollTop() {
    fetchNextPage();
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const isMobileDevice = () => window.innerWidth < 768;
  const showMediaButtons = !isTextareaFocused || !isMobileDevice();

  useEffect(() => {
    // Only scroll if the tribe ID has changed (chat switch)
    if (
      status === "success" &&
      data &&
      previousTribeId.current !== selectedTribe._id
    ) {
      requestAnimationFrame(() => {
        const messageContainer = document.querySelector(
          '[data-message-container="true"]'
        );
        if (messageContainer) {
          messageContainer.scrollTop = messageContainer.scrollHeight;
        }
      });
      previousTribeId.current = selectedTribe._id;
    }
  }, [status, data, selectedTribe._id]);

  if (!selectedTribeId) {
    return (
      <div className="h-full flex items-center justify-center text-primary-300">
        Select a chat to start messaging
      </div>
    );
  }

  if (status === "error") {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-primary-300 text-center">
          There was an error retrieving the chat messages
        </p>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex-shrink-0 pb-6">
        <ChatHeader
          onBackClick={onBackClick}
          tribe={selectedTribe}
          onExitChatClick={() => {
            dispatch(
              openExitTribeConfirmationModal({
                tribe_id: selectedTribe._id,
                user_id: user._id,
                event: selectedTribe,
              })
            );
          }}
          onToggleMembers={onToggleMembers}
          showMembers={showMembers}
        />
      </div>

      {/* Message List Area */}
      <div className="flex-1 min-h-0 overflow-hidden">
        {status === "pending" ? (
          <div className="h-full w-full">
            <LoadingSpinner />
          </div>
        ) : (
          <ChatMessageList
            numberOfPages={data.pages.length}
            onScrollTop={handleScrollTop}
            user={user}
            chatMessages={data.pages}
            ref={messagesEndRef}
            selectedTribe={selectedTribe}
          />
        )}
      </div>

      {/* Input Area */}
      <div
        id={CHAT_MESSAGE_CLASS}
        className="flex-shrink-0 w-full bg-primary-900 backdrop-blur-xl bg-opacity-30"
      >
        <div className="p-4 pb-[calc(env(safe-area-inset-bottom)+1rem)]">
          <div className="flex items-center gap-2">
            <div
              className={`transition-all duration-300 ease-in-out ${
                showMediaButtons
                  ? "w-auto opacity-100"
                  : "w-0 opacity-0 overflow-hidden"
              }`}
            >
              <div className="flex gap-2">
                <GifSelector
                  onGifSelect={(gif: any) => {
                    sendMessageMutation({
                      newMessage: gif?.images?.original?.url,
                      type: "gif",
                    });
                    setTimeout(() => {
                      messagesEndRef.current?.scrollIntoView();
                    }, 0);
                  }}
                />
                <ChatImageUploader />
              </div>
            </div>
            <TextareaAutosize
              value={message}
              onChange={(event) => setMessage(event.currentTarget.value)}
              onKeyDown={handleKeyDown}
              onFocus={() => setIsTextareaFocused(true)}
              onBlur={() => setIsTextareaFocused(false)}
              className="flex-1 rounded-2xl w-full resize-none text-primary-100 border-gray-800 bg-primary-900 px-4 py-2 lg:text-sm focus:border-primary-500 focus:outline-none transition-all duration-300 ease-in-out"
              placeholder="Type a message..."
              minRows={1}
              maxRows={4}
            />
            <button
              onClick={sendMessage}
              className="flex items-center justify-center w-9 h-9 rounded-full bg-utility-blue transition-colors"
              aria-label="Send message"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-white"
              >
                <path
                  d="M22 2L11 13M22 2L15 22L11 13M22 2L2 9L11 13"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageArea;
