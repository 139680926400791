import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogTitle,
} from "@radix-ui/react-dialog";
import { useFormik } from "formik";
import { useState } from "react";
import { Button } from "./Button";
import { User } from "./VerifyUserInfo";
import { addUserDetails } from "../../api/api";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/Reducers/userReducer";
import * as Yup from "yup";
import Datepicker from "react-tailwindcss-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Progress from "@radix-ui/react-progress";
// @ts-ignore-next-line
import WelcomeLogo from "../../assets/img/WelcomeLogo.svg";
import { getPostOnboardingAction } from "../../utils/postOnboardingAction";

const validationSchema = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string(),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  dob: Yup.date()
    .required("Date of birth is required")
    .test("age", "You must be at least 18 years old", (value) => {
      if (!value) return false;
      const today = new Date();
      const birthDate = new Date(value);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age >= 18;
    }),
});

interface Props {
  user: User;
}

function CompleteProfileModal({ user }: Props) {
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = 4;
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      dob: user?.dob ? new Date(user.dob) : null,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await addUserDetails({
          ...values,
          is_profile_completed: true,
        });

        if (response?.statusCode === 200) {
          dispatch(setUser(response?.data));

          const postAction = getPostOnboardingAction();

          if (postAction && postAction.action && postAction.eventId) {
            const redirectUrl = `/event/${postAction.eventId}?from=onboarding&action=${postAction.action}`;

            navigate(redirectUrl);
          } else {
            navigate("/");
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className="space-y-4 flex flex-col justify-between min-h-full">
            <div className="space-y-0">
              <div className="flex justify-center items-center">
                <img src={WelcomeLogo} alt="Welcome Logo" className="w-1/3" />
              </div>

              <div className="flex justify-between items-center pt-2">
                <DialogTitle className="text-3xl text-center font-semibold text-primary-100 w-full">
                  Welcome to Tribein! 🎉
                </DialogTitle>
              </div>
            </div>

            <div className="space-y-6 flex-grow pt-2 flex flex-col items-center">
              <p className="text-primary-200 text-center font-medium text-lg">
                We're thrilled to have you join the Tribe! Whether you're here
                to find your festival squad, concert crew, or game-day rally
                team, you're in the right place.
              </p>

              <div className="space-y-3">
                <div className="flex items-center space-x-3 text-primary-200">
                  <span className="text-primary-100">⚡</span>
                  <span>
                    Quick warm-up! Setting up your profile takes less time than
                    a halftime show. Seriously, we're talking 20 seconds—clock
                    us if you want!
                  </span>
                </div>
                <div className="flex items-center space-x-3 text-primary-200">
                  <span className="text-primary-100">🔒</span>
                  <span>
                    Your info's VIP. No sharing, no snooping—just secure,
                    private, and ready to help us personalize your experience.
                  </span>
                </div>
                <div className="flex items-center space-x-3 text-primary-200">
                  <span className="text-primary-100">🎯</span>
                  <span>
                    All about you. Your profile helps us connect you with your
                    kind of people for events you love.
                  </span>
                </div>
              </div>

              <p className="text-primary-200 text-center font-medium">
                Let's get started—your next event with your Tribe is just a few
                clicks away!
              </p>
            </div>

            <Button onClick={handleNext} className="w-full" size="lg">
              Let's Get Started
            </Button>
          </div>
        );

      case 1:
        return (
          <div className="space-y-4 flex flex-col justify-between min-h-full">
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <DialogTitle className="text-xl md:text-2xl font-semibold text-primary-100">
                  👋 Let's get to know you
                </DialogTitle>
                <StepNumber />
              </div>
              <Progress.Root
                className="relative overflow-hidden bg-primary-900 rounded-full w-full h-1.5"
                value={25}
              >
                <Progress.Indicator
                  className="bg-primary-100 w-full h-full transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]"
                  style={{ transform: `translateX(-${100 - 25}%)` }}
                />
              </Progress.Root>
            </div>

            <div className="space-y-4 flex-grow pt-4">
              <div>
                <label
                  htmlFor="first_name"
                  className="block text-sm mb-1 font-medium text-gray-200"
                >
                  What's your first name?
                </label>
                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  placeholder="Enter your first name"
                  className="bg-primary-900 w-full text-primary-200 h-10 rounded-md text-sm px-2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                />
                {formik.touched.first_name && formik.errors.first_name && (
                  <p className="mt-1 text-sm text-red-600">
                    {formik.errors.first_name}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="last_name"
                  className="block text-sm mb-1 font-medium text-gray-200"
                >
                  And your last name? (Optional)
                </label>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  placeholder="Enter your last name"
                  className="bg-primary-900 w-full text-primary-200 h-10 rounded-md text-sm px-2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                />
              </div>
            </div>

            <Button
              onClick={handleNext}
              disabled={
                !formik.values.first_name || Boolean(formik.errors.first_name)
              }
              className="w-full"
              size="lg"
            >
              Continue
            </Button>
          </div>
        );

      case 2:
        return (
          <div className="space-y-4 flex flex-col justify-between h-full">
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <DialogTitle className="text-xl md:text-2xl font-semibold text-primary-100">
                  📅 When's your birthday, <br className="md:hidden" />
                  <span className="pl-6 md:pl-0">
                    {formik.values.first_name.charAt(0).toUpperCase() +
                      formik.values.first_name.slice(1)}
                  </span>
                  ?
                </DialogTitle>
                <StepNumber />
              </div>
              <Progress.Root
                className="relative overflow-hidden bg-primary-900 rounded-full w-full h-1.5"
                value={50}
              >
                <Progress.Indicator
                  className="bg-primary-100 w-full h-full transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]"
                  style={{ transform: `translateX(-${100 - 50}%)` }}
                />
              </Progress.Root>
            </div>

            <div className="flex-grow pt-4 [&>div>div>div]:dark:bg-primary-900 [&>div>div>div]:dark:text-primary-200 [&>div>div>div]:text-primary-200 [&>div>div>div]:dark:border-primary-900 [&>div>div>div]:border-primary-900 [&>div>div>div]:bg-primary-900 [&>div>div>div]:md:max-h-[320px] [&>div>div>div]:overflow-y-auto">
              <label
                htmlFor="dob"
                className="block text-sm mb-1 font-medium text-gray-200"
              >
                Select your date of birth
              </label>
              <Datepicker
                useRange={false}
                asSingle={true}
                value={{
                  startDate: formik.values.dob,
                  endDate: formik.values.dob,
                }}
                onChange={async (newValue) => {
                  const selectedDate = newValue?.startDate
                    ? new Date(newValue.startDate)
                    : null;

                  if (selectedDate) {
                    selectedDate.setHours(0, 0, 0, 0);
                  }

                  await formik.setFieldValue("dob", selectedDate);

                  await formik.validateField("dob");

                  await formik.setFieldTouched("dob", true, true);

                  await formik.validateForm();
                }}
                inputClassName="bg-primary-900 w-full text-primary-200 h-10 rounded-md text-sm px-2 z-[9999]"
                placeholder="Select your date of birth"
                showShortcuts={false}
                maxDate={new Date()}
                primaryColor="blue"
              />
              {formik.touched.dob && formik.errors.dob && (
                <p className="mt-1 text-sm text-red-600">{formik.errors.dob}</p>
              )}
            </div>

            <div className="flex space-x-3">
              <Button
                onClick={handleBack}
                variant="secondary"
                className="w-full"
                size="lg"
              >
                Back
              </Button>
              <Button
                onClick={handleNext}
                disabled={!formik.values.dob || Boolean(formik.errors.dob)}
                className="w-full"
                size="lg"
              >
                Continue
              </Button>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-4 flex flex-col justify-between h-full">
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <DialogTitle className="text-xl md:text-2xl font-semibold text-primary-100">
                  ✨ Almost there!
                </DialogTitle>
                <StepNumber />
              </div>
              <Progress.Root
                className="relative overflow-hidden bg-primary-900 rounded-full w-full h-1.5"
                value={75}
              >
                <Progress.Indicator
                  className="bg-primary-100 w-full h-full transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]"
                  style={{ transform: `translateX(-${100 - 75}%)` }}
                />
              </Progress.Root>
            </div>

            <div className="flex-grow pt-4">
              <label
                htmlFor="email"
                className="block text-sm mb-1 font-medium text-gray-200"
              >
                What's your email address?
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Enter your email"
                className="bg-primary-900 w-full text-primary-200 h-10 rounded-md text-sm px-2"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <p className="mt-1 text-sm text-red-600">
                  {formik.errors.email}
                </p>
              )}
            </div>

            <div className="flex space-x-3">
              <Button
                onClick={handleBack}
                variant="secondary"
                className="w-full"
                size="lg"
              >
                Back
              </Button>
              <Button
                onClick={() => formik.handleSubmit()}
                disabled={!formik.isValid || formik.isSubmitting}
                className="w-full whitespace-nowrap"
                size="lg"
              >
                Create account
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const StepNumber = () => {
    return (
      <span className="text-lg text-primary-500 font-bold">
        {currentStep}/{totalSteps - 1}
      </span>
    );
  };

  return (
    <Dialog
      open={isModalOpen}
      onOpenChange={(open) => {
        if (open === false) {
          return;
        }
        setIsModalOpen(open);
      }}
      modal={true}
    >
      <DialogOverlay className="fixed inset-0 bg-black/50 z-40" />
      <DialogContent
        onInteractOutside={(e) => e.preventDefault()}
        className="fixed 
          bg-white overflow-hidden
          w-full 
          h-[100dvh] md:h-[600px]
          md:flex md:flex-col md:justify-center md:items-center
          max-h-[100dvh] md:max-h-full
          max-w-none
          md:max-w-2xl 
          z-[9999]
          md:rounded-lg rounded-none
          md:top-1/2 md:-translate-y-1/2 md:left-1/2 md:-translate-x-1/2"
      >
        <div className="bg-primary-800 p-4 md:p-6 md:rounded-lg w-full h-full overflow-y-auto">
          {renderStepContent()}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CompleteProfileModal;
