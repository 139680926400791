import { useMutation, useQueryClient } from "@tanstack/react-query";
import { exitTribe, deleteTribe } from "../api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeExitTribeConfirmationModal } from "../redux/Reducers/modalReducer";

interface ExitTribeParams {
  tribe_id: string;
  user_id: string;
  memberCount: number;
}

export const useExitTribe = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ tribe_id, user_id, memberCount }: ExitTribeParams) => {
      const exitResponse = await exitTribe({ tribe_id, user_id });

      if (exitResponse.statusCode !== 200) {
        throw new Error(exitResponse.message || "Failed to exit tribe");
      }

      console.log(memberCount);

      // If this was the last member, delete the tribe
      if (memberCount === 1) {
        const deleteResponse = await deleteTribe(tribe_id);
        if (deleteResponse.statusCode !== 200) {
          throw new Error(deleteResponse.message || "Failed to delete tribe");
        }
      }

      return exitResponse;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tribes"] });
      queryClient.invalidateQueries({ queryKey: ["tribeCounts"] });
      navigate("/chat");
      dispatch(closeExitTribeConfirmationModal({}));
      toast.success("Successfully exited tribe");
    },
    onError: (error: Error) => {
      toast.error(error.message || "Failed to exit tribe");
    },
  });
};
