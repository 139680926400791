import "./layout.css";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar.tsx";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import classnames from "classnames";

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const isChat = pathname.includes("/chat");

  return (
    <>
      <Helmet>
        <meta name="description" content="TribeIn " />
        <title>TribeIn</title>
      </Helmet>
      <div className="layout">
        <div className="sidebar_wraper">
          <div className={`main_wraper`}>
            {pathname !== ROUTES.SIGN_UP ? <Navbar hidden={isChat} /> : null}
            <div
              className={classnames({
                "pt-20 md:pt-32": pathname !== ROUTES.CHAT,
                "pt-0": pathname === ROUTES.CHAT,
              })}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
