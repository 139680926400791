import Modal from "react-bootstrap/Modal";
import { Link, useLocation } from "react-router-dom";
import WelcomeLogo from "../../assets/img/WelcomeLogo.svg";
import Fb from "../../assets/img/Fb.svg";
import Apple from "../../assets/img/Apple.svg";
import Google from "../../assets/img/Google.svg";
import { useDispatch } from "react-redux";
import {
  closeLogin,
  closeSignup,
  openPhoneSignIn,
} from "../../redux/Reducers/modalReducer";
import { updatePostOnboardingData } from "../../utils/postOnboardingAction";
import Button from "react-bootstrap/Button";
import { useState } from "react";

interface LoginModalProps {
  prefix: "in" | "up";
  show: boolean;
  onHide: () => void;
}

const LoginModal = (props: LoginModalProps) => {
  const dispatch = useDispatch();
  const route = useLocation();
  const pathname = route.pathname;
  const [prefix, setPrefix] = useState(props.prefix);

  const handlePostOnboardingAction = () => {
    const hasEventId = pathname.includes("/event/");
    if (!hasEventId) return;

    updatePostOnboardingData({
      action: "view_event",
      eventId: pathname.split("/").pop() || "",
    });
  };

  const handleSignUpWithPhone = () => {
    handlePostOnboardingAction();
    dispatch(openPhoneSignIn({}));
  };

  const handleGoogleSignup = () => {
    handlePostOnboardingAction();
    dispatch(closeSignup({}));
    dispatch(closeLogin({}));
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/google/signup`;
  };

  const handleAppleSignup = () => {
    handlePostOnboardingAction();
    dispatch(closeSignup({}));
    dispatch(closeLogin({}));
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/apple/signup`;
  };

  const handleFacebookSignup = () => {
    handlePostOnboardingAction();
    dispatch(closeSignup({}));
    dispatch(closeLogin({}));
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/facebook/signup`;
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="sign_in_modal welcome_modal bg-gradient-to-b from-slate-950  via-indigo-950 to-slate-950 animate-gradient bg-[length:100%_400%]"
      centered
    >
      <style>{`
          @keyframes gradient {
            0% {
              background-position: 50% 0%;
            }
            50% {
              background-position: 50% 100%;
            }
            100% {
              background-position: 50% 0%;
            }
          }
          .animate-gradient {
            animation: gradient 20s cubic-bezier(0.4, 0, 0.2, 1) infinite;
          }
        `}</style>
      <Modal.Header closeButton className="p-0">
        <Modal.Title className="m-0"></Modal.Title>
      </Modal.Header>
      <div className="h-full">
        <div className="h-full w-full">
          <div className="relative">
            <div className="bg-black/70 absolute top-0 left-0 w-full h-full rounded-md overflow-hidden"></div>
          </div>
        </div>
        <div className="welcome_logo">
          <div className="flex justify-center">
            <img src={WelcomeLogo} alt="WelcomeLogo" />
          </div>
          <div className="text-md font-medium mb-8">
            <p>Connect. Engage. Experience Live Events Together.</p>
          </div>
          <div className="welcm_btn_list">
            <ul>
              <li>
                <Button
                  variant="light"
                  className="white_btn text-uppercase"
                  onClick={handleAppleSignup}
                >
                  <img src={Apple} alt="apple" />
                  Sign {prefix} WITH APPLE
                </Button>
              </li>
              <li>
                <Button
                  variant="light"
                  className="white_btn text-uppercase"
                  onClick={handleGoogleSignup}
                >
                  <img src={Google} alt="Google" />
                  Sign {prefix} WITH GOOGLE
                </Button>
              </li>
              <li>
                <Button
                  variant="light"
                  className="white_btn facebook_btn text-uppercase"
                  onClick={handleFacebookSignup}
                >
                  <img src={Fb} alt="Facebook" />
                  Sign {prefix} WITH FACEBOOK
                </Button>
              </li>
              <li>
                <Button
                  variant="light"
                  className="sign_up_with text-uppercase"
                  onClick={handleSignUpWithPhone}
                >
                  Sign {prefix} WITH PHONE NUMBER
                </Button>
              </li>
            </ul>
          </div>

          <div className="already_have">
            <p className="text-sm">
              {prefix === "up"
                ? "Already have an account?"
                : "Don't have an account?"}{" "}
              <Button
                variant="link"
                className="text-utility-pink font-semibold text-sm"
                onClick={() => {
                  setPrefix(prefix === "up" ? "in" : "up");
                }}
              >
                {prefix === "up" ? "Sign In" : "Sign Up"}
              </Button>
            </p>

            <p className="text-sm">
              By signing {prefix} {prefix === "up" ? "up" : "in"} TribeIn, you
              agree to our{" "}
              <Link
                to="/termsconditions"
                onClick={() => dispatch(closeSignup({}))}
              >
                terms and conditions
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
