import { uploadFile } from "../api/api";
import moment from "moment";
import {
  AGE_PREFERENCES,
  GENDER_PREFERENCES,
  MUSIC_GENRES,
  ZODIAC_SIGNS,
} from "./constant";
import dayjs from "dayjs";

export const formatDate = (dateString, isTimeRequired = true) => {
  // Parse the input dateString using Moment.js
  const parsedDate = moment.utc(dateString);

  // Format date
  const formattedDate = parsedDate.format("ddd, MMM D, YYYY");

  // Format time if required
  if (isTimeRequired) {
    const formattedTime = parsedDate.format("HH:mm");
    return `${formattedDate} • ${formattedTime}`;
  }

  return formattedDate;
};

export const markAllFieldTouched = (formik) => {
  for (const field in formik.initialValues) {
    formik.setFieldTouched(field, true);
  }
};

export const removeSubstringFromBeginning = (
  originalString,
  substringToRemove
) => {
  if (originalString.startsWith(substringToRemove)) {
    return originalString.slice(substringToRemove.length);
  }
  return originalString;
};

export const formatDateRange = (startDate, endDate) => {
  // Convert startDate and endDate to UTC using Moment.js
  const utcStartDate = moment(startDate).utc().format("MMM DD, YYYY");
  const utcEndDate = moment(endDate).utc().format("MMM DD, YYYY");

  // Define the date formatting options
  // const options = { year: "numeric", month: "short", day: "2-digit" };

  // // Format UTC dates according to options
  // const formattedStartDate = new Date(utcStartDate).toLocaleDateString(
  //   "en-US",
  //   options
  // );
  // const formattedEndDate = new Date(utcEndDate).toLocaleDateString(
  //   "en-US",
  //   options
  // );

  // Return formatted dates
  return {
    startDate: utcStartDate,
    endDate: utcEndDate,
  };
};

// funtion to format the Date of Birth when provided in a string with time.
export const formatDateOfBirth = (dateString) => {
  return dayjs(dateString).format("MM/DD/YYYY");
};

// function to format filter date
export const formatFilterDate = (dateString) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
// Function to map value to label for MUSIC_GENRES
export function mapGenreValueToLabel(value) {
  const genre = MUSIC_GENRES.find((genre) => genre.value === value);
  return genre ? genre.label : value;
}

// Function to map value to label for AGE_PREFERENCES
export function mapAgeValueToLabel(value) {
  const age = AGE_PREFERENCES.find((age) => age.value === value);
  return age ? age.label : value;
}

// Function to map value to label for GENDER_PREFERENCES
export function mapGenderValueToLabel(value) {
  const gender = GENDER_PREFERENCES.find((gender) => gender.value === value);
  return gender ? gender.label : value;
}

export function calculateAge(dateOfBirthString) {
  const dob = new Date(dateOfBirthString);
  const currentDate = new Date();
  const diffMs = currentDate - dob;
  const age = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365));
  return age;
}

export function calculateZodiacSign(dateOfBirthString) {
  const dob = new Date(dateOfBirthString);
  const month = dob.getMonth() + 1;
  const day = dob.getDate();

  let sign = "";
  for (const zodiac of ZODIAC_SIGNS) {
    const startDate = new Date(`${dob.getFullYear()}-${zodiac.start}`);
    const endDate = new Date(`${dob.getFullYear()}-${zodiac.end}`);
    if (
      (month === startDate.getMonth() + 1 && day >= startDate.getDate()) ||
      (month === endDate.getMonth() + 1 && day <= endDate.getDate())
    ) {
      sign = zodiac.sign;
      break;
    }
  }

  return sign;
}

//function to get the location of the user.
export async function getUserLocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      const options = {
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: 60000,
      };
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const location = { latitude, longitude };
          resolve(location);
        },
        (error) => {
          reject(error);
        },
        options
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
}

// function to remove the prefix of the profile picture name stored in the database
export function removePrefix(filePath) {
  if (filePath.startsWith("public/uploads/")) {
    return filePath.slice("public/uploads/".length);
  } else {
    return filePath;
  }
}

// transform the genres
export function transformGenres(genres) {
  return genres.map((genre) => ({
    identifier: genre.name,
    value: genre.name.toLowerCase(),
  }));
}

export function getGenreNameById(id, genresArray) {
  const genre = genresArray.find((genre) => genre._id === id);
  return genre ? genre.name : null;
}

export const calculateDestination = (lon1, lat1, distance = 50) => {
  const R = 6371.0;

  const delta = distance / R;

  const lat1Rad = toRadians(lat1);
  const lon1Rad = toRadians(lon1);

  const bearingRad = toRadians(90);

  const lat2Rad = Math.asin(
    Math.sin(lat1Rad) * Math.cos(delta) +
      Math.cos(lat1Rad) * Math.sin(delta) * Math.cos(bearingRad)
  );
  const lon2Rad =
    lon1Rad +
    Math.atan2(
      Math.sin(bearingRad) * Math.sin(delta) * Math.cos(lat1Rad),
      Math.cos(delta) - Math.sin(lat1Rad) * Math.sin(lat2Rad)
    );

  const lat2Deg = toDegrees(lat2Rad);
  const lon2Deg = toDegrees(lon2Rad);

  return { lon2Deg, lat2Deg };
};

const toRadians = (deg) => {
  return deg * (Math.PI / 180);
};

const toDegrees = (rad) => {
  return rad * (180 / Math.PI);
};

export const sortByName = (arr) => {
  return arr.sort((a, b) => a.name.localeCompare(b.name));
};

export const truncateString = (inputString) => {
  if (inputString.length <= 25) {
    return inputString;
  } else {
    return inputString.substring(0, 25) + " ...";
  }
};

// export const formatDates = (dateStrings) => {
//   const formattedDates = dateStrings.map((dateString, index) => {
//     // new Date(moment(userDetail?.dateOfBirth).utc().format('MM-DD-YYYY'))
//     const date = new Date(moment(dateString).utc().format("MM-DD-YYYY"));
//     let options = null;
//     if (index === 0) {
//       options = { month: "short", day: "numeric" };
//     } else {
//       options = { day: "numeric" };
//     }
//     return date.toLocaleDateString("en-US", options);
//   });
//   // Join the formatted dates with commas and spaces
//   return formattedDates.join(", ");
// };

export const formatDates = (dateStrings) => {
  const formattedDates = dateStrings.map((dateString, index) => {
    const date = moment(dateString).utc();
    let formattedDate;
    if (index === 0) {
      formattedDate = date.format("MMM DD");
    } else {
      formattedDate = date.format("DD");
    }
    // console.log(dateString, formattedDate, ">>>><<<<>");
    return formattedDate;
  });

  // Join the formatted dates with commas and spaces
  return formattedDates.join(", ");
};

// function to check tribe name contain the year 2024 or not.
export const contains2024 = (str) => {
  // Check if the string contains "2024"
  return str.includes("2024");
};

// function to find the return true or if the user had already joined any tribe.
export const userExistsInTribes = (tribesArray, userId) => {
  for (let tribe of tribesArray) {
    if (tribe.tribeUsers && tribe.tribeUsers.length > 0) {
      for (let user of tribe.tribeUsers) {
        if (user.user_id === userId) {
          return true;
        }
      }
    }
  }
  return false;
};

// function to find the tribe id if the user already joined any tribe
export const findTribeIdByUserId = (tribesArray, userId) => {
  for (let tribe of tribesArray) {
    if (tribe.tribeUsers && tribe.tribeUsers.length > 0) {
      for (let user of tribe.tribeUsers) {
        if (user.user_id === userId) {
          return tribe._id;
        }
      }
    }
  }
  return null;
};

export const extractCoordinates = (response) => {
  if (!response || !response.loc) {
    return { latitude: null, longitude: null };
  }

  // Split the 'loc' string into latitude and longitude
  const [latitude, longitude] = response.loc.split(",");

  return {
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
  };
};
