import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SendMessages } from "../../../api/api";
import { useSocket } from "../../../context/SocketContext";
import { MessageSchema, MessageTribe, SenderSchema } from "./messages.schema";

interface MessageSenderProps {
  sender_id: string;
  tribe_id: string;
  tribeName: string;
  user: SenderSchema;
  tribe: MessageTribe;
}

interface SendMessageParams {
  newMessage: string;
  type?: string;
}

export const useSendMessage = ({
  sender_id,
  tribe_id,
  user,
  tribe,
}: MessageSenderProps) => {
  const socket = useSocket();
  const queryClient = useQueryClient();

  const { mutate: sendMessage } = useMutation({
    mutationFn: async ({ newMessage, type }: SendMessageParams) => {
      const messageData = {
        sender_id,
        tribe_id,
        type: type ?? "text",
        message: newMessage,
      };
      const response = await SendMessages(messageData);
      return response.data;
    },
    onMutate: async (newMessage) => {
      await queryClient.cancelQueries({ queryKey: ["messages", tribe_id] });

      const optimisticMessage = {
        _id: `temp-${Date.now()}`,
        sender_id,
        tribe_id,
        type: newMessage.type ?? "text",
        message: newMessage.newMessage,
        read_by: [sender_id],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        sender: user,
        tribe,
      };

      queryClient.setQueryData(["messages", tribe_id], (old: any) => {
        if (!old) {
          return {
            pages: [
              {
                docs: [optimisticMessage],
                totalDocs: 1,
                hasMore: false,
                currentPage: 1,
                totalPages: 1,
                limit: 50,
              },
            ],
            pageParams: [1],
          };
        }

        return {
          ...old,
          pages: [
            {
              ...old.pages[0],
              docs: [...old.pages[0].docs, optimisticMessage],
            },
            ...old.pages.slice(1),
          ],
        };
      });

      return { optimisticMessage };
    },
    onSuccess: (
      data,
      _variables: SendMessageParams,
      context: { optimisticMessage: MessageSchema } | undefined
    ) => {
      if (!context) return;

      queryClient.setQueryData(["messages", tribe_id], (old: any) => {
        if (!old) return old;

        return {
          ...old,
          pages: old.pages.map((page: any) => ({
            ...page,
            docs: page.docs.map((msg: any) =>
              msg._id === context.optimisticMessage._id
                ? { ...msg, ...data, _id: data._id }
                : msg
            ),
          })),
        };
      });

      socket.emit("send message", data);
    },
  });

  return { sendMessage };
};
