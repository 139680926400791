import { useQuery } from "@tanstack/react-query";
import { usersTribes } from "../../../api/api";
import { TribesPaginationResponseSchema } from "./tribes.schema";

export const useTribes = () => {
  const query = {
    search_query: "",
    sort_by: "createdAt",
    page: 1,
    limit: 100,
    order: -1,
  };

  const response = useQuery({
    queryKey: ["tribes"],
    queryFn: () => usersTribes(query),
    select: (response) => {
      return TribesPaginationResponseSchema.parse(response.data);
    },
  });

  return response;
};
