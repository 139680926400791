import React, { useEffect } from "react";
import "../assets/scss/login-animation.scss";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import WelcomeLogo from "../assets/img/WelcomeLogo.svg";
import Fb from "../assets/img/Fb.svg";
import Apple from "../assets/img/Apple.svg";
import Google from "../assets/img/Google.svg";
import { useDispatch } from "react-redux";
import {
  closeLogin,
  closeSignup,
  openPhoneSignIn,
} from "../redux/Reducers/modalReducer";
import { useNavigate } from "react-router-dom";

const LoginPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const handleSignUpWithPhone = () => {
    dispatch(openPhoneSignIn());
  };

  const handleGoogleSignup = () => {
    dispatch(closeSignup());
    dispatch(closeLogin());
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/google/signup`;
  };

  const handleAppleSignup = () => {
    dispatch(closeSignup());
    dispatch(closeLogin());
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/apple/signup`;
  };

  const handleFacebookSignup = () => {
    dispatch(closeSignup());
    dispatch(closeLogin());
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/facebook/signup`;
  };

  return (
    <div className="min-h-[86vh] relative flex items-center justify-center">
      <div className="login-page-gradient" />
      <div className="login-page-overlay" />

      <div className="relative z-10 w-full max-w-lg px-4 py-8">
        <style jsx global>{`
          @keyframes gradient {
            0% {
              background-position: 50% 0%;
            }
            50% {
              background-position: 50% 100%;
            }
            100% {
              background-position: 50% 0%;
            }
          }
          .animate-gradient {
            animation: gradient 20s cubic-bezier(0.4, 0, 0.2, 1) infinite;
          }
        `}</style>
        <div className="h-full sign_in_modal welcome_modal flex flex-col justify-center items-center bg-gradient-to-b from-slate-950  via-indigo-950 to-slate-950 animate-gradient">
          <div className="h-full w-full">
            <div className="relative">
              <div className="bg-black/70 absolute top-0 left-0 w-full h-full rounded-md overflow-hidden"></div>
            </div>
          </div>
          <div className="welcome_logo">
            <div className="flex justify-center">
              <Link to="/">
                <img src={WelcomeLogo} alt="WelcomeLogo" />
              </Link>
            </div>
            <div className="text-md font-medium mb-8">
              <p>Connect. Engage. Experience Live Events Together.</p>
            </div>
            <div className="welcm_btn_list">
              <ul>
                <li>
                  <Button
                    variant="light"
                    className="white_btn text-uppercase"
                    onClick={handleAppleSignup}
                  >
                    <img src={Apple} alt="apple" />
                    Sign Up With Apple
                  </Button>
                </li>
                <li>
                  <Button
                    variant="light"
                    className="white_btn text-uppercase"
                    onClick={handleGoogleSignup}
                  >
                    <img src={Google} alt="Google" />
                    Sign Up With Google
                  </Button>
                </li>
                <li>
                  <Button
                    variant="light"
                    className="white_btn facebook_btn text-uppercase"
                    onClick={handleFacebookSignup}
                  >
                    <img src={Fb} alt="Facebook" />
                    Sign Up With Facebook
                  </Button>
                </li>
                <li>
                  <Button
                    variant="light"
                    className="sign_up_with text-uppercase"
                    onClick={handleSignUpWithPhone}
                  >
                    Sign Up With Phone Number
                  </Button>
                </li>
              </ul>
            </div>

            <div className="already_have">
              <p className="text-sm">
                By signing up for TribeIn, you agree to our{" "}
                <Link
                  to="/termsconditions"
                  onClick={() => dispatch(closeSignup())}
                >
                  terms and conditions
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
