import { useMemo } from "react";

interface Props {
  text: string;
  size?: "xs" | "sm" | "md" | "lg";
}

function GradientProfile(props: Props) {
  const { text, size = "md" } = props;

  // Memoize both the gradients and the random selection
  const { randomGradient } = useMemo(() => {
    const gradients = [
      ["#f3f4f6", "#e5e7eb"], // Light gray
      ["#dbeafe", "#bfdbfe"], // Light blue
      ["#dcfce7", "#bbf7d0"], // Light green
      ["#fef3c7", "#fde68a"], // Light yellow
      ["#ffe4e6", "#fecdd3"], // Light rose
      ["#f5f3ff", "#ede9fe"], // Light purple
      ["#fff7ed", "#fed7aa"], // Light orange
    ];
    return {
      randomGradient: gradients[Math.floor(Math.random() * gradients.length)],
    };
  }, []);

  const imageSize = () => {
    if (size === "xs") return "h-6 w-6";
    if (size === "sm") return "h-8 w-8";
    if (size === "lg") return "h-20 w-20";
    return "h-10 w-10";
  };

  const textSize = () => {
    if (size === "xs") return "text-sm";
    if (size === "sm") return "text-sm";
    if (size === "lg") return "text-lg";
    return "text-base";
  };

  return (
    <div
      className={`inline-flex rounded-full items-center text-gray-700 text-sm font-bold justify-center ${imageSize()}`}
      style={{
        background: `linear-gradient(135deg, ${randomGradient?.[0]}, ${randomGradient?.[1]})`,
      }}
    >
      <div className={textSize()}>{text.toUpperCase()}</div>
    </div>
  );
}

export default GradientProfile;
