export const ROUTES = {
  // Public Routes
  HOME: "/",
  SIGN_UP: "/sign-up",
  CONCERTS: "/concerts",
  EVENTS: "/events",
  EVENT_DETAILS: "/event/:identifier",
  TERMS_CONDITIONS: "/termsconditions",
  TEST: "/test",

  // Protected Routes
  PROFILE: "/profile",
  MY_EVENTS: "/myevents",
  MY_TRIBES: "/myTribes",
  CHAT: "/chat",
  USER_PROFILE: "/user/profile/:user_id",
  SUGGEST_FEATURE: "/suggestfeature",
  FESTIVALS: "/festivals",

  // Additional Routes
  REPORT_USER: "/Reportuser",
  FILTER: "/Fillter",
  TRIBE_WILL: "/Tribewill",
  FIRST_MESSAGE: "/firstmessage",
  REQUEST_SUBMITTED: "/Requestsuccessfullysubmitted",
  YOUR_FEEDBACK: "/yourfeedback",

  // Utility Routes
  NOT_FOUND: "*",
} as const;

// Helper function to generate dynamic routes
export const generatePath = {
  userProfile: (userId: string) => `/user/profile/${userId}`,
  eventDetails: (identifier: string) => `/event/${identifier}`,
};
