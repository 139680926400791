import CompleteProfileModal from "../../Component/shared/CompleteProfileModal";

const UpdateProfile = (props) => {
  const { user } = props;

  if (!user || user.is_profile_completed) {
    return null;
  }

  return <CompleteProfileModal user={user} />;
};

export default UpdateProfile;
