import React from "react";
import { Spinner } from "react-bootstrap";

interface Props {}

function LoadingSpinner(props: Props) {
  const {} = props;

  return (
    <div className=" flex flex-col justify-center items-center h-full w-full gap-2 animate-slideIn">
      <Spinner className="text-primary-600" />
      <div className="text-sm font-medium text-primary-600">Loading Chat</div>
    </div>
  );
}

export default LoadingSpinner;
