import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { joinTribes, createTribe, getTribes } from "../api/api";
import { MAX_TRIBE_USERS } from "../helpers/constants";
import { closeTribe } from "../redux/Reducers/modalReducer";
import { Tribe, TribeUser } from "@/types/tribes.types";

interface JoinTribeParams {
  event_id: string;
  tribe_id?: string;
}

export const useJoinTribe = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.user);

  return useMutation({
    mutationFn: async ({ event_id, tribe_id }: JoinTribeParams) => {
      if (tribe_id) {
        const response = await joinTribes({ tribe_id });
        if (response.statusCode !== 201) {
          throw new Error(response.message || "Failed to join tribe");
        }
        return response;
      }

      const tribesResponse = await getTribes({
        event_id,
        search_query: "",
        page: 1,
        limit: 1000,
        sortBy: "createdAt",
        order: -1,
      });

      if (tribesResponse.statusCode === 200) {
        const tribes = tribesResponse.data.docs;

        const availableTribe = tribes.find((tribe: Tribe) => {
          const memberCount = tribe.tribeUsers?.length ?? 0;
          const isUserMember = tribe.tribeUsers?.some(
            (tu: TribeUser) => tu.user_id === user._id
          );
          return memberCount < MAX_TRIBE_USERS && !isUserMember;
        });

        if (tribes.length === 0 || !availableTribe) {
          const newTribeResponse = await createTribe({
            name: `Tribe ${tribes.length + 1}`,
            event_id,
          });

          if (newTribeResponse.statusCode !== 201) {
            throw new Error("Failed to create initial tribe");
          }

          return;
        }

        return joinTribes({ tribe_id: availableTribe._id });
      }

      throw new Error("Failed to fetch tribes");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tribeCounts"] });
      navigate("/chat");
      dispatch(closeTribe({}));
    },
    onError: (error: Error) => {
      toast.error(error?.message ?? "There was an error joining the tribe");
    },
  });
};
