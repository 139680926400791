import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Tribe } from "../../../types/tribes.types";
import GradientProfile from "../../shared/GradientProfile";
import { openExitTribeConfirmationModal } from "../../../redux/Reducers/modalReducer";
import { Button } from "../../shared/Button";

interface Props {
  tribe: Tribe;
  onClose: () => void;
}

export default function TribeInfoSidebar({ tribe, onClose }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.user);

  const handleExitTribe = () => {
    dispatch(
      openExitTribeConfirmationModal({
        tribe_id: tribe._id,
        user_id: user._id,
        event: tribe,
      })
    );
  };

  return (
    <>
      {/* Mobile overlay */}
      <div className="fixed inset-0 bg-black/50 lg:hidden" onClick={onClose} />

      {/* Sidebar */}
      <div className="md:pt-20 fixed inset-y-0 right-0 w-full max-w-[24rem] xl:static lg:w-[600px] border-l border-primary-700 bg-primary-950 overflow-hidden flex flex-col bg-card-background">
        {/* Header */}
        <div className="p-4">
          <div className="flex items-center gap-2">
            <button
              onClick={onClose}
              className="p-2 -ml-2 hover:bg-primary-800 rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-5 h-5 text-primary-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </button>
            <h3 className="text-primary-100 text-lg font-medium mb-0">
              Tribe info
            </h3>
          </div>
        </div>

        {/* Scrollable Content */}
        <div className="flex-1 overflow-y-auto">
          <div className="px-4">
            {/* Members Section */}
            <div className="mb-8">
              <h4 className="text-primary-100 mb-4 text-lg font-semibold">
                Members
              </h4>
              <ul className="space-y-2">
                {Array.from(new Set(tribe.joinees?.flat()?.map((j) => j._id)))
                  .map((id) => tribe.joinees?.flat()?.find((j) => j._id === id))
                  .filter(Boolean)
                  .map((joinee) => (
                    <li key={joinee?._id}>
                      <button
                        onClick={() => {
                          if (user._id !== joinee?._id) {
                            navigate(`/user/${joinee?._id}`);
                          } else {
                            navigate("/profile");
                          }
                        }}
                        className="flex items-center justify-between w-full p-2 rounded-lg hover:bg-primary-700 transition-colors group"
                      >
                        <div className="flex items-center gap-2.5">
                          {joinee?.profile_pic ? (
                            <img
                              src={joinee?.profile_pic}
                              alt={`${joinee?.first_name}'s profile`}
                              className="w-8 h-8 rounded-full object-cover"
                            />
                          ) : (
                            <GradientProfile
                              size="sm"
                              text={joinee?.first_name?.slice(0, 1) || "U"}
                            />
                          )}
                          <div className="text-left">
                            <div className="text-primary-100 font-medium text-sm">
                              {joinee?.first_name}
                            </div>
                            {user._id === joinee?._id && (
                              <div className="text-primary-400 text-xs">
                                You
                              </div>
                            )}
                          </div>
                        </div>
                        <button className="p-1 opacity-0 group-hover:opacity-100 transition-opacity">
                          <svg
                            className="w-5 h-5 text-primary-300"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.625 7.5C3.625 8.12132 3.12132 8.625 2.5 8.625C1.87868 8.625 1.375 8.12132 1.375 7.5C1.375 6.87868 1.87868 6.375 2.5 6.375C3.12132 6.375 3.625 6.87868 3.625 7.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM13.625 7.5C13.625 8.12132 13.1213 8.625 12.5 8.625C11.8787 8.625 11.375 8.12132 11.375 7.5C11.375 6.87868 11.8787 6.375 12.5 6.375C13.1213 6.375 13.625 6.87868 13.625 7.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </button>
                      </button>
                    </li>
                  ))}
              </ul>
            </div>

            {/* Blocked Members Section */}
            {/* <div>
              <h4 className="text-primary-100 mb-4 text-lg font-semibold">
                Blocked members
              </h4>
              <div className="text-primary-400 text-sm">No blocked members</div>
            </div> */}
          </div>
        </div>

        {/* Exit Button */}
        <div className="p-4  border-primary-700">
          <Button
            onClick={handleExitTribe}
            size="lg"
            className="w-full py-3 px-4 rounded-lg bg-transparent hover:bg-primary-700 text-primary-100 font-medium transition-colors border border-white"
          >
            EXIT TRIBE
          </Button>
        </div>
      </div>
    </>
  );
}
