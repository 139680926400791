import React from "react";
import { Button } from "../../Component/shared/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Succes from "../../assets/img/Succes.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout, setUser } from "../../redux/Reducers/userReducer";
import { closeConfirmationModal } from "../../redux/Reducers/modalReducer";
import { useNavigate } from "react-router-dom";

const ConfirmationModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state?.model);
  const handleYes = () => {
    switch (data.mode) {
      case "logout":
        dispatch(logout());
        dispatch(setUser(null));

        localStorage.removeItem("token");
        navigate("/");
        break;
      default:
        break;
    }
    dispatch(closeConfirmationModal());
  };

  const handleNo = () => {
    dispatch(closeConfirmationModal());
  };

  return (
    <>
      <Modal
        {...props}
        className="sign_in_modal categories_modal fillter_categories_modal request_successfully_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="reportsuccessfully_cntnt flex flex-col items-center sm:justify-center">
            <div className="reportsuccessfully_txt">
              <h2 className="text-white font-bold">{data?.message}</h2>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-center items-center gap-2 w-full">
            <Button
              variant="destructive"
              size="lg"
              className="w-full"
              onClick={handleYes}
            >
              Yes
            </Button>
            <Button
              variant="default"
              size="lg"
              className="w-full"
              onClick={handleNo}
            >
              No
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
