import dayjs from "dayjs";

interface Props {
  startDate: string;
}

function EventCardCountdown(props: Props) {
  const { startDate } = props;

  const diff = dayjs(startDate).diff(dayjs(), "hour");
  const diffMinutes = dayjs(startDate).diff(dayjs(), "minute");
  const daysLeft = Math.floor(diff / 24);
  const hoursLeft = diff % 24;

  return (
    <div className="bg-gradient-to-r from-primary-900/70 to-primary-800/90 backdrop-blur-md text-white rounded-lg px-2.5 py-1.5 absolute top-2 left-2 flex gap-3 shadow-lg border-[0.5px] border-primary-600 group-hover:border-[#FF53DE] transition-all duration-300">
      <div className="flex flex-col items-center leading-none">
        <span className="text-white font-bold text-base">{daysLeft}</span>
        <span className="text-white/80 font-medium text-[0.6rem] uppercase tracking-wider mt-0.5">
          {daysLeft === 1 ? "Day" : "Days"}
        </span>
      </div>
      <div className="relative flex flex-col items-center leading-none">
        <div className="absolute left-[-0.5rem] top-1/2 -translate-y-1/2 w-[1px] h-[70%] bg-white/20" />
        <span className="text-white font-bold text-base">
          {hoursLeft < 1 && daysLeft === 0 ? diffMinutes : hoursLeft}
        </span>
        <span className="text-white/80 font-medium text-[0.6rem] uppercase tracking-wider mt-0.5">
          {hoursLeft < 1 && daysLeft === 0 ? "Minutes" : "Hours"}
        </span>
      </div>
    </div>
  );
}

export default EventCardCountdown;
