import dayjs from "dayjs";
import { useEffect, useRef, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Event } from "./event.schema";
import "./event.scss";
import EventCardCountdown from "./EventCardCountdown.tsx";
import mixpanel from "mixpanel-browser";
import { Calendar1Icon, CircleDollarSign, MapPinIcon } from "lucide-react";
import * as Tooltip from "@radix-ui/react-tooltip";

type Props = {
  event: Event;
};

export const DAYJS_FORMAT = "ddd, MMM D, YYYY";

const TextWithTooltip = ({
  text,
  className,
}: {
  text: string | undefined;
  className?: string;
}) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      const isTextOverflowing = element.scrollWidth > 268;
      setIsOverflowing(isTextOverflowing);
    }
  }, [text]);

  if (!text) return null;

  return isOverflowing ? (
    <Tooltip.Provider delayDuration={0}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <span ref={textRef} className={className}>
            {text}
          </span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="rounded-md bg-black/80 backdrop-blur-sm px-3 py-2 text-sm text-white flex gap-2 max-w-[342px] border-[0.5px] border-primary-700 shadow-lg animate-in duration-200"
            sideOffset={1}
          >
            <span className="text-white font-medium text-base">{text}</span>
            <Tooltip.Arrow className="fill-primary-900/50" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  ) : (
    <span ref={textRef} className={className}>
      {text}
    </span>
  );
};

const EventCard = ({ event }: Props) => {
  function getLocation(venue: Event["event_venue"]) {
    if (venue?.city && venue?.state && venue?.name) {
      return `${venue.name} ${venue.city}, ${venue?.state}`;
    }

    if (venue?.city && venue?.name) {
      return `${venue.name} ${venue.city}`;
    }

    if (venue?.city) {
      return venue.city;
    }

    if (venue?.state && venue?.country) {
      return `${venue.state}`;
    }
    return "";
  }

  const isMissingStartTime = event.event_start_date.includes("00:00:00");
  const localStartDate = dayjs(event.event_start_date).format(DAYJS_FORMAT);
  const utcStartDate = dayjs(event.event_start_date).utc().format(DAYJS_FORMAT);
  let startDate = isMissingStartTime ? utcStartDate : localStartDate;

  let endTime = undefined;
  if (event.event_end_date) {
    const isMissingEndTime = event.event_end_date.includes("00:00:00");
    const localEndDate = dayjs(event.event_end_date).format(DAYJS_FORMAT);
    const utcEndDate = dayjs(event.event_end_date).utc().format(DAYJS_FORMAT);
    endTime = isMissingEndTime ? utcEndDate : localEndDate;
  }

  const localEndDate = endTime;

  const endDate = !event.event_end_date ? undefined : localEndDate;

  const isWithinTwoWeeks = dayjs(event.event_start_date).isBefore(
    dayjs().add(14, "days")
  );
  const isInPast = dayjs(event.event_start_date).isBefore(dayjs());

  const firstTicketPrice = useMemo(() => {
    const firstTicket = event.tickets?.[0];
    const firstTicketType = firstTicket?.ticket_types?.[0];
    const price = firstTicketType?.price;

    return typeof price === "number" && !isNaN(price) ? price : null;
  }, [event.tickets]);

  function getGenreName(): string {
    if (!event.genre && typeof !event.sport) {
      return "";
    }

    const genre = event?.genre[0]?.name;
    const sport = event?.sport;

    const invalidNames = ["", "null", "undefined", "Undefined", "Null"];

    const genreType = event.event_type === "sports" ? sport : genre;

    if (typeof genreType === "string") {
      return invalidNames.includes(genreType) ? "" : genreType;
    } else {
      return "";
    }
  }

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div className="bg-card-background h-full max-h-full overflow-hidden rounded-lg max-w-[500px] min-h-full border-[0.5px] border-transparent hover:border-[0.5px] hover:border-[#FF53DE] transition-all duration-300 group">
      <Link
        onClick={() => {
          mixpanel.track("Clicked event", event);
        }}
        to={`/event/${event._id}`}
        className="h-full w-[342px] flex flex-col justify-between"
      >
        <div className="w-full">
          <div className="relative">
            {isWithinTwoWeeks && !isInPast && (
              <EventCardCountdown startDate={event.event_start_date} />
            )}
            {getGenreName() && (
              <div className="absolute top-2 right-2 bg-[#FF53DE] backdrop-blur-sm text-white z-10 px-2 py-1 text-sm font-bold rounded-lg">
                {getGenreName()}
              </div>
            )}

            <div>
              <img
                className="h-[200px] w-full object-cover"
                loading="lazy"
                alt="Event"
                src={event.banner_image}
              />
              <div className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-b from-transparent via-black/10 to-black/80" />
              <h3 className="mb-0 text-xl line-clamp-1 font-semibold absolute bottom-2 left-2.5 text-white">
                {event.event_name}
              </h3>
            </div>
          </div>
          <div className="px-2.5 py-3 flex flex-col gap-2">
            <div className="flex gap-2">
              <Calendar1Icon className="h-4 w-4 text-white flex-shrink-0" />
              <span className="text-white font-medium text-sm">
                {startDate} {endDate ? `- ${endDate}` : undefined}
              </span>
            </div>
            <div className="flex gap-2">
              <MapPinIcon className="h-4 w-4 text-white flex-shrink-0" />{" "}
              <TextWithTooltip
                text={getLocation(event.event_venue)}
                className="text-white font-medium text-sm line-clamp-1"
              />
            </div>

            <div className="flex items-center gap-2">
              <CircleDollarSign className="h-4 w-4 text-white flex-shrink-0" />
              {firstTicketPrice ? (
                <span className="text-white font-medium text-sm">
                  From: {currencyFormatter.format(firstTicketPrice)}
                </span>
              ) : (
                <span className="text-white font-medium text-sm">
                  Check on Partner's Site
                </span>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export { EventCard };
