import UpdateProfile from "./UpdateProfile";
import { UserProfile } from "../../types/user.types";
import { useQuery } from "@tanstack/react-query";

interface Props {
  user: UserProfile;
}

function UpdateProfileWrapper({ user }: Props) {
  const ipInfo = useQuery({
    queryKey: ["ipInfo"],
    queryFn: async () => {
      if (user?.address?.loc?.coordinates) {
        return {
          city: user.address.name,
          lat: user.address.loc.coordinates[0],
          lng: user.address.loc.coordinates[1],
        };
      }

      const response = await fetch("https://ipapi.co/json/");
      return response.json();
    },
    enabled: !user?.address?.loc?.coordinates,
  });

  if (ipInfo.isLoading) return <div>Loading...</div>;
  if (ipInfo.isError) return <UpdateProfile />;

  if (user?.address?.loc?.coordinates) {
    return (
      <UpdateProfile
        user={user}
        name={user.address.name}
        lat={user.address.loc.coordinates[0]}
        lng={user.address.loc.coordinates[1]}
      />
    );
  }

  return (
    <UpdateProfile
      user={user}
      name={ipInfo.data?.city}
      lat={ipInfo.data?.latitude}
      lng={ipInfo.data?.longitude}
    />
  );
}

export default UpdateProfileWrapper;
