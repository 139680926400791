import { z } from "zod";

// Schema for a single joinee
const JoineeSchema = z.array(
  z.array(
    z.object({
      _id: z.string(),
      first_name: z.string(),
      profile_pic: z.string().nullable(),
      last_name: z.string().optional(),
    })
  )
);

// Schema for a single tribe
const TribeSchema = z.object({
  _id: z.string(),
  name: z.string(),
  event_id: z.string(),
  event_name: z.string().optional(),
  admin_id: z.string().optional(),
  tribe_logo: z.string(),
  is_deleted: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
  totalJoinees: z.number(),
  joinees: JoineeSchema,
  unreadMessagesCount: z.number(),
  totalMessagesCount: z.number(),
});

const TribesPaginationResponseSchema = z.object({
  docs: z.array(TribeSchema),
  totalDocs: z.number().optional(),
  hasMore: z.boolean().optional(),
  currentPage: z.number().optional(),
  totalPages: z.number().optional(),
  limit: z.number().optional(),
});

export type Tribe = z.infer<typeof TribeSchema>;
export type TribesPaginationResponse = z.infer<
  typeof TribesPaginationResponseSchema
>;

export { TribesPaginationResponseSchema };
